import i18n from '@/i18n'

export enum SelectionTypes {
  checked = 'checked',
  promote = 'promote',
  featured = 'featured',
  highlighted = 'highlighted',
}

const selectionTypesConfig = {
  types: {
    [SelectionTypes.promote]: {
      icon: () => import('@/assets/icons/selection-types/inline.promote.svg'),
      title: i18n.t('Promote'),
    },
    [SelectionTypes.featured]: {
      icon: () => import('@/assets/icons/selection-types/inline.featured.svg'),
      title: i18n.t('Featured'),
    },
    [SelectionTypes.highlighted]: {
      icon: () => import('@/assets/icons/selection-types/inline.highlighted.svg'),
      title: i18n.t('Highlighted'),
    },
    [SelectionTypes.checked]: {
      icon: () => import('@/assets/icons/inline.checked.svg'),
      title: i18n.t('Checked'),
    },
  },
}

export default selectionTypesConfig
